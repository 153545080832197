<template>
  <div class="CartIcon pl-12 desk:pl-20">
    <button
      class="desk:flex items-center relative cursor-pointer"
      @click="openCart"
      @mouseenter="sendDebounce"
    >
      <div
        class="relative basis-20 shrink-0"
        :class="{
          favoriteAnimateIn: showAnimation
        }"
      >
        <img
          src="/icons/cart.svg"
          class="w-20 h-20 inline-block"
          :alt="$t('header.cart')"
        >
        <div
          v-if="cartStore.isLoading"
          class="absolute top-[11px] desk:top-[9px] min-w-20 text-center type-xxs-medium leading-single"
        >
          <img
            src="/fontawesome/darkest/solid/spinner.svg"
            class="h-8 w-8 spin inline-block"
            :alt="$t('loading')"
          >
        </div>
        <div
          v-else-if="cartItems > 0"
          class="absolute top-[11px] desk:top-[9px] min-w-20 text-center type-xxs-medium leading-single"
        >
          {{ cartItems }}
        </div>
      </div>
      <span class="ml-12 hidden deskStd:block whitespace-nowrap">{{ $t('header.cart') }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import { storeToRefs } from 'pinia';
const uiStore = useUiStore();

const cartStore = useCartStore();

const { cartItems } = storeToRefs(cartStore);

const openCart = () => {
  cartStore.sendDebouncedQueue();
  uiStore.setShowMiniCart(true);
};

const sendDebounce = () => {
  cartStore.sendDebouncedQueue();
};

const showAnimation = ref(false);
watch(cartItems, (newVal, oldVal)=> {
  if (newVal > oldVal) {
    showAnimation.value = true;
    setTimeout(()=> showAnimation.value = false, 2000);
  }
});

</script>

<style scoped lang="postcss">
</style>
