<template>
  <div
    class="ImpersonatingNotice min-h-32 transition-all flex-wrap duration-500 bg-darkest text-lightest w-full type-headline-xxs relative z-header desk:flex-nowrap desk:h-32"
  >
    <div class="h-full">
      <div
        class="px-16 h-full py-[10px] bg-attentionPrimary type-headline-xxs text-darkest flex justify-center"
      >
        <div
          class="flex flex-col justify-center desk:flex-row desk:layout-container "
        >
          <div class="flex flex-col justify-center items-center desk:flex-row">
            {{ $t('siteNotice.loggedInAs', { name: userProfile.company.name, id: userProfile.company.code}) }}
            <div
              class="flex underline cursor-pointer ml-4"
              @click="stopImpersonating"
            >
              {{ userStore.isCustomerSuccessUser ? $t('siteNotice.changeSalon.customerSuccess') : $t('siteNotice.changeSalon') }}
            </div>
            <span v-if="orderProposalId" class="px-4">|</span>
            <div v-if="orderProposalId" class="flex ml-12">
              {{ $t('siteNotice.editingOrderProposal') }} #{{ orderProposalId }}
            </div>
            <div class="flex flex-nowrap justify-center items-center">
              <div v-if="orderProposalId" class="flex">
                <nuxt-link
                  :to="$manualUrl('/')"
                  class="ml-12 underline cursor-pointer"
                >
                  {{ $t('checkout.orderProposal.addMore') }}
                </nuxt-link>
                <span class="px-4">/</span>
                <div class="underline cursor-pointer" @click="clearBasket()">
                  {{ $t('checkout.orderProposal.cancel') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import { storeToRefs } from 'pinia';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useUiStore } from '~/store/ui';

const uiStore = useUiStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const { userProfile } = storeToRefs(userStore);
const { salesUrl } = useLinkReplacer();

const stopImpersonating = async() => {
  if (userStore.isCustomerSuccessUser) {
    uiStore.setShowLogInAsCustomer(true);
  } else {
    await userStore.stopImpersonatingCompany();
    navigateTo(salesUrl('customers'));
  }
};

const clearBasket = async() => {
  await userStore.setCurrentlyEditingOrderProposal(null);
  cartStore.deleteCart();
  navigateTo(salesUrl('orderProposals'));
};

const orderProposalId = computed(() => {
  return userStore.isEditingOrderProposal ? userStore.userProfile.currentlyEditingOrderProposal?.norceBasketId : null;
});
</script>

<style lang="postcss">
.truncate-p > p {
  @apply truncate text-10 desk:text-12 leading-single;
}
.usp-text {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.usp-text.active {
  opacity: 1;
}
</style>
