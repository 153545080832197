<template>
  <header
    class="CheckoutHeader header bg-lightest text-darkest sticky top-0 w-full z-header"
  >
    <div class="shadow-bottomLine">
      <div class="flex h-[56px] items-center justify-between desk:layout-container">
        <div class="basis-1/3">
          <nuxt-link
            v-if="!isPointShop"
            :to="manualUrl('/')"
            class="mobOnly:pl-16 pr-8 flex"
            :external="true"
          >
            <img src="/icons/arrow-left.svg" class="h-24 w-24 desk:mr-12">
            <span class="hidden desk:inline type-headline-sm relative top-2">
              {{ $t('checkout.backToShopping') }}
            </span>
          </nuxt-link>
        </div>
        <div class="block basis-[112px] shrink-0 text-center">
          <nuxt-link
            :to="manualUrl('/')"
            :external="true"
          >
            <img
              :src="`/logos/iveo-storefront_new.svg`"
              class="w-full h-auto"
              alt="logo"
            >
          </nuxt-link>
        </div>
        <div class="basis-1/3">
          <!--        <button class="mobOnly:pr-32 pl-8 inline-block  desk:flex relative top-2" @click="uiStore.setShowCheckoutHelp(true)">
            <img src="/fontawesome/lightest/regular/headset.svg" class="h-18 desk:mr-12 relative desk:top-3">
            <span class="hidden desk:inline type-sm-medium">
              {{ $t('checkout.support') }}
            </span>
          </button>-->
        </div>
      </div>
    </div>

    <PointshopHead v-if="isPointShop" />
  </header>
</template>

<script setup lang="ts">
/**
 * Please note, the link to the frontpage has :external="true" to force a full load
 * This is because, if checkout is reloaded, normal navigation will fail
 */
import PointshopHead from '~/components/checkout/PointshopHead.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';
const { manualUrl } = useLinkReplacer();

const route = useRoute();

const isPointShop = computed(()=> {
  return route.name?.toString()?.endsWith('checkout-pointshop-basketId');
});
</script>

<style scoped lang="postcss">
.header {
  backdrop-filter: blur(15px);
}
</style>
