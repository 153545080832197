<template>
  <component
    :is="GenericSideMenu"
    position="right"
    class="EditProductDiscountModal"
    :title="$t('checkout.orderProposal.editDiscount')"
    @close="uiStore.closeAllModal()"
  >
    <MiniCartProduct
      v-for="item in items"
      :key="item.partNo"
      :item="item"
      :hide-delete="true"
      :hide-multi-discount="true"
    />

    <div v-if="loadingDiscounts" class="flex items-center justify-center my-8">
      <LoadingIcon />
    </div>
    <div v-else>
      <div v-if="userStore.isCustomerSuccessUser" class="my-24 mb-36">
        <div class="flex relative items-end">
          <div class="basis-full">
            <TextInput
              v-model="csDiscountPercentage"
              :type="'number'"
              :label="$t('priceInfo.discountLabel') + ' %'"
              :attr="{min: '0', max: '100'}"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="discounts.length"
        class="flex flex-wrap align-items gap-6 mb-12 mt-16"
        :class="{
          'mb-24': !showDiscountQuantity,
        }"
      >
        <div v-for="(discount) in discounts" :key="'discount-' + discount.id">
          <button
            class="btn btn--secondary items-center mb-8"
            :class="{
              'disabled': updatingDiscount,
              'selected': selectedDiscount == discount.code
            }"
            :disabled="updatingDiscount"
            @click="onSelect(discount)"
          >
            {{ discount.name }}
          </button>
        </div>
      </div>
      <div v-else class="py-24 text-14">
        {{ $t('checkout.orderProposal.noDiscounts') }}
      </div>

      <div
        v-if="showDiscountQuantity"
        class="type-sm-medium mb-24"
      >
        <div class="flex relative items-end">
          <div class="basis-full">
            <TextInput
              v-model="selectDiscountQuantity"
              :type="'number'"
              :label="userStore.isCustomerSuccessUser ? $t('minicart.discount.productQuantity') : $t('minicart.discount.giftQuantity')"
            />
          </div>
          <button
            class="btn !px-12 ml-12 absolute right-0"
            :class="{ 'loading': loading }"
            :disabled="loading"
            @click="applyDiscount"
          >
            {{ $t('minicart.discount.activate') }}
          </button>
        </div>
      </div>
    </div>
    <button
      :class="{ 'disabled': loading }"
      class="btn w-full btn--confirm mb-24"
      @click="uiStore.closeAllModal()"
    >
      {{ $t('search.cancel') }}
    </button>
  </component>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import useApiFetch from '~/composeables/useApiFetch';
import MiniCartProduct from '~/components/cart/MiniCartProduct.vue';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import TextInput from '~/components/form-elements/TextInput.vue';

const uiStore = useUiStore();
const userStore = useUserStore();
const cartStore = useCartStore();

const { apiGet, handleApiError } = useApiFetch();
const csDiscountPercentage = ref<number>(0);
const discounts = ref<{id: number, code: string, name: string}[]>([]);
const items = ref([]);
const loadingDiscounts = ref(false);
const selectedDiscount = ref(0);
const selectDiscountQuantity = ref(0);
const loading = ref(false);
const updatingDiscount = ref(false);
const showDiscountQuantity = ref(false);

onMounted(()=> {
  loadDiscountOptions();
  setItem();
});

const loadDiscountOptions = (async() => {
  loadingDiscounts.value = true;
  if (userStore.isSalesRepUser) {
    const res = await apiGet(`/products/${uiStore.currentlyEditingItem.partNo}/discounts`);
    if (res.length > 0) {
      discounts.value = res;
    }
  }

  loadingDiscounts.value = false;
});

const onSelect = (async(discount) => {
  showDiscountQuantity.value = true;
  selectedDiscount.value = Number(discount.code);
});

const setItem = () => {
  items.value = [uiStore.currentlyEditingItem];

  if ((selectedDiscount.value !== 0 && discounts.length) || userStore.isCustomerSuccessUser) {
    showDiscountQuantity.value = true;
  }
};

const applyDiscount = async() => {
  updatingDiscount.value = true;
  loading.value = true;
  let res = null;
  const discount = userStore.isSalesRepUser
    ? selectedDiscount.value
    : csDiscountPercentage.value;

  if (selectDiscountQuantity.value > 0) {
    res = await cartStore.updateProductDiscount(Number(uiStore.currentlyEditingItem.lineNo), discount.toString(), selectDiscountQuantity.value);
    if (res) {
      cartStore.cart = res;
      uiStore.currentlyEditingItem = res.items?.filter(item => item.lineNo === uiStore.currentlyEditingItem.lineNo)[0];
    } else {
      handleApiError();
    }
  }

  updatingDiscount.value = false;
  loading.value = false;
};

watch(() => uiStore.currentlyEditingItem, () => {
  setItem();
});
</script>

<style scoped lang="postcss">
</style>
