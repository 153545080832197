<template>
  <footer v-if="footer" class="PageFooter bg-lighter">
    <FooterMenu />
    <div class="layout-container desk:border-t desk:border-light desk:min-h-0 pt-16 pb-32 flex flex-col justify-between">
      <div class="mobOnly:px-0 flex flex-col desk:flex-row justify-between items-center gap-24">
        <div class="text-center pt-16 order-last desk:order-first desk:pt-0 type-sm leading-single text-darkest">
          {{ footer.copyright.replace('##YEAR##', new Date().getFullYear()) }}
        </div>
        <div>
          <div v-if="footer && footer.footerLogos" class="flex flex-row flex-wrap justify-center gap-8 order-first desk:order-last">
            <div v-for="(image, index) in footer.footerLogos" :key="index">
              <nuxt-img
                preset="standard"
                loading="lazy"
                sizes="sm:100vw desk:1260px"
                class="h-24 w-auto object-cover"
                :src="image.filename"
                :alt="image.alt || 'Logo'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import FooterMenu from '~/components/body/FooterMenu.vue';

const globalContent = useGlobalContentStore();

const footer = globalContent.getFooter;

</script>

<style scoped lang="postcss">

</style>
