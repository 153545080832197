<template>
  <div
    class="TempError flex justify-center fixed top-[75vh] desk:top-[85vh] z-globalError w-full  cursor-pointer"
    @click="uiStore.tempErrorMsg = ''"
  >
    <div class="errormsg pl-32 pr-24 py-12 bg-criticalDark text-lightest type-sm-medium text-center flex items-center">
      <div>{{ uiStore.tempErrorMsg }}</div>
      <div class="ml-24"><img src="/fontawesome/lightest/regular/xmark.svg" class="w-16 h-16"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';

const uiStore = useUiStore();

</script>

<style scoped lang="postcss">
.errormsg {
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.23);
}
</style>
