<template>
  <div class="FooterMenu layout-container py-64 type-xs desk:type-sm">
    <div v-if="footer.logo?.filename" class="layout-container pb-64 mb-64 border-b border-light">
      <nuxt-img
        preset="standard"
        loading="lazy"
        class="w-full h-auto"
        :src="footer.logo?.filename"
        :alt="footer.logo?.alt"
      />
    </div>
    <div class="desk:flex desk:layout-container desk:justify-between">
      <div class="desk:basis-1/2 grow-0 desk:mr-24">
        <div class="type-headline-sm">
          {{ footer.footerTitle }}
        </div>
        <div class="wysiwyg type-sm desk:w-2/3 mt-16" v-html="renderRichText(footer.footerText)" />
      </div>
      <div
        class="desk:basis-1/2 desk:flex justify-between mobOnly:mt-16"
      >
        <div
          class="desk:grid basis-full shrink"
          :class="`grid-cols-${navigation.length}`"
        >
          <div
            v-for="link in navigation"
            :key="link._uid"
            class="mobOnly:border-b mobOnly:border-light mobOnly:py-16"
          >
            <div
              class="flex justify-between"
              @click="clickMenu($sbLinkFix(link.link), (link.children.length > 0), link._uid)"
            >
              <div class="type-headline-sm">
                {{ link.title }}
              </div>
              <div v-if="link.children.length" class="cursor-pointer">
                <img
                  v-if="!expanded.includes(link._uid)"
                  src="/icons/plus.svg"
                  class="w-20 h-20 desk:!hidden"
                >
                <img
                  v-else
                  src="/icons/minus.svg"
                  class="w-20 h-20 desk:!hidden"
                >
              </div>
            </div>
            <transition name="fade">
              <div v-if="link.children.length && (!isMobile || expanded.includes(link._uid))" class="pt-8">
                <nuxt-link
                  v-for="child in link.children"
                  :key="child._uid"
                  class="block mt-12 first:mt-8"
                  :to="$sbLinkFix(child.link)"
                  :target="$sbLinkTarget(child.link)"
                >
                  <div class="hover:underline">{{ child.title }}</div>
                </nuxt-link>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';

import { storeToRefs } from 'pinia';

const uiStore = useUiStore();

const { isMobile } = storeToRefs(uiStore);
const globalContent = useGlobalContentStore();

const navigation = globalContent.getFooterNav;
const footer = globalContent.getFooter;

const router = useRouter();
const expanded = ref<string[]>([]);
const clickMenu = (url:string, children:boolean, _uid:string) => {
  if (children) {
    if (expanded.value.includes(_uid)) {
      expanded.value = expanded.value.filter(v => v !== _uid);
    } else {
      expanded.value.push(_uid);
    }
  } else {
    router.push({ path: `${url}` });
  }
};

// const openCountrySelect = () => {
//   uiStore.openSideMenuWith({ uid: 'selectCountry', ignoreParent: true });
// };

</script>

<style scoped lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
